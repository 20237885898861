import React, { useState, useEffect, useRef } from 'react'
import { useFormik } from 'formik'
import axios from 'axios'
import styled from 'styled-components'
import { Form } from './Form'
import { Confirm } from './Confirm'
import { Complete } from './Complete'
import { customMedia } from '../common/customMedia'
import { Image } from '../common/Image'
import { MyButton } from '../common/MyButton'

export const Contact = () => {
  const [confirm, setConfirm] = useState(false)
  const [complete, setComplete] = useState(false)

  const formik = useFormik({
    validateOnChange: false,
    initialValues: {
      type: 'Mail',
      agree: null,
      firstname: '',
      lastname: '',
      firstnameKana: '',
      lastnameKana: '',
      company: '',
      department: '',
      email: '',
      tel: '',
      content: ''
    },
    validate: values => {
      const errors: { [key: string]: string } = {}

      if (!values.agree) {
        errors.agree = '個人情報の取り扱いへの同意は必須です'
      } else if (values.agree === 'disagree') {
        errors.agree = '個人情報の取り扱いに同意してください'
      }
      if (!values.firstname) {
        errors.firstname = '氏名（漢字）姓 は必須です'
      }
      if (!values.lastname) {
        errors.lastname = '氏名（漢字）名 は必須です'
      }
      if (!values.firstnameKana) {
        errors.firstnameKana = '氏名（フリガナ）姓 は必須です'
      }
      if (!values.lastnameKana) {
        errors.lastnameKana = '氏名（フリガナ）名 は必須です'
      }
      if (!values.email) {
        errors.email = 'メールアドレスは必須です'
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
      ) {
        errors.email = 'メールアドレス が無効な形式です'
      }
      if (!values.tel) {
        errors.tel = 'ご連絡先電話番号は必須です'
      }
      if (!values.content) {
        errors.content = 'お問い合わせ内容は必須です'
      }

      return errors
    },
    onSubmit: values => {
      if (confirm) {
        axios
          .post(
            'https://us-central1-remohab-prod.cloudfunctions.net/sendMail',
            {
              data: values
            }
          )
          .then(res => {
            console.log(res)
            setComplete(true)
          })
          .catch(e => {
            console.log(e)
          })
      } else {
        setConfirm(true)
      }
    }
  })

  return (
    <>
      <Container>
        <Wrapper>
          <MailIconWrapper>
            <Image path="mail@2x.png" />
          </MailIconWrapper>

          {complete ? (
            <Complete />
          ) : confirm ? (
            <form onSubmit={formik.handleSubmit}>
              <Confirm formik={formik} />
              <ButtonWrapper>
                <MyButton type="submit" text="送信" />
              </ButtonWrapper>
            </form>
          ) : (
            <form onSubmit={formik.handleSubmit}>
              <Form formik={formik} />
              <ButtonWrapper>
                <MyButton type="submit" text="入力内容を確認する ＞" />
              </ButtonWrapper>
            </form>
          )}
        </Wrapper>
      </Container>
    </>
  )
}

const Container = styled.div`
  ${customMedia.lessThan('medium')`
    padding: 75px 20px;
  `}
  ${customMedia.greaterThan('medium')`
    padding: 120px 0;
  `}
  ${customMedia.greaterThan('large')`
    padding: 150px 0;
  `}

  background-color: #efefef;
`

const Wrapper = styled.div`
  ${customMedia.lessThan('medium')`
  `}
  ${customMedia.greaterThan('medium')`
    margin: 0 auto;
    width: 735px;
  `}
  ${customMedia.greaterThan('large')`
    margin: 0 auto;
    width: 1000px;
  `}

  position: relative;
`

const MailIconWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    margin-bottom: 30px;
    width: 44px;
  `}
  ${customMedia.greaterThan('medium')`
    margin-bottom: 70px;
    width: 88px;
  `}

  margin: 0 auto;
`

const ButtonWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    margin-top: 44px;
  `}
  ${customMedia.greaterThan('medium')`
    margin-top: 117px;
  `}

  text-align: center;
`
