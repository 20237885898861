import React from 'react'
import styled from 'styled-components'

import { Layout } from '../components/Layout'
import { SEO } from '../components/common/SEO'

import { Contact } from '../components/Contact'

const IndexPage = () => (
  <Layout>
    <SEO
      title=''
      description=''
      keywords={[]}
    />
    <Contact />
  </Layout>
)

export default IndexPage
